:root {
  --primary-color: #f1f5fa;
  --darkBlue-color: #0b223e;
  --font-family: Sora;
  --lightBlue-color: #B6C9E4;
  --lightDark-color: #4B4B4B;
  --divider-color:#D4DDFF
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body>* {
  font-family: var(--font-family) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}